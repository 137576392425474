const $ = require('jquery');
global.$ = global.jQuery = $;

window.Popper = require('popper.js');
require('bootstrap');
require('datatables.net-bs4');
require('select2');
require('bootstrap');
require('@fortawesome/fontawesome-free');

$(document).ready(function () {
    let tableTellingen = $('#dtTellingen').DataTable({
        "stateSave": true,
        "stateSaveParams": function (settings, data) {
            data.filialen_keuze = $('#filialen_keuze').val();
            data.statusfilter = $('#statusfilter').val();
        },
        "stateLoadParams": function (settings, data) {
            $('#filialen_keuze').val(data.filialen_keuze);
            $('#statusfilter').val(data.statusfilter);
        },
        "searching": true,
        "ordering": true,
        "order": [[1, "asc"]],
        "lengthMenu": [[10, 25, 50, 100, 500], [10, 25, 50, 100, 500]],
        "columnDefs": [
            {
                "targets": 'no-sort',
                "orderable": false,
            },
        ],
        "footerCallback": function (row, data, start, end, display) {
            var api = this.api(), data;

            // Remove the formatting to get integer data for summation
            var intVal = function (i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                        i : 0;
            };

            // Aantalscans totaal van de actieve pagina
            let aantalscansTotal = api
                .column(3, {page: 'current'})
                .data()
                .reduce(function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0);

            // Update footer
            $(api.column(2).footer()).html(countfunction);
            $(api.column(3).footer()).html(aantalscansTotal);
        },
        "language": {
            "url": "/translations/Dutchlocations.json"
        }
    });

    $('#datatableInventarisatie').on('keyup change blur', 'tbody td:not(:last-child) .saveinput', function (e) {
        let inventarisatieID = $(this).closest('[data-id]').data('id');
        let saveName = this.name;
        let saveValue = this.value;

        $.ajax({
            type: "GET",
            url: '/inventarisaties/aanpassen/' + inventarisatieID,
            async: true,
            data: {inventarisatieID, saveName, saveValue},
            success: function () {
                console.log('success');
            }
        });
    });

    $('#datatableLocaties').on('keyup change blur', 'tbody td:not(:last-child) .saveinput', function (e) {
        let locatieID = $(this).closest('[data-id]').data('id');
        let saveName = this.name;
        let saveValue = this.value;

        $.ajax({
            type: "GET",
            url: '/locaties/aanpassen/' + locatieID,
            async: true,
            data: {locatieID, saveName, saveValue},
            success: function () {
                console.log('success');
            }
        });
    });

    $('#datatableFiliaal').on('keyup change blur', 'tbody td:not(:last-child) .saveinput', function (e) {
        let filiaalID = $(this).closest('[data-id]').data('id');
        let saveName = this.name;
        let saveValue = this.value;

        $.ajax({
            type: "GET",
            url: '/filialen/aanpassen/' + filiaalID,
            async: true,
            data: {filiaalID, saveName, saveValue},
            success: function () {
                console.log('success');
            }
        });
    });


    if ($('#statusfilter').length) {
        tableTellingen.column(4).search($('#statusfilter').val()).draw();
        tableTellingen.draw();

        $('#statusfilter').change(function () {
            let val = this.value;
            tableTellingen.column(4).search(val ? '^' + this.value + '$' : val, true, false).draw();
            tableTellingen.draw();
        });
    }


    if ($('#dtclearstateDiv').length) {
        localStorage.clear();
    }

    $("#exportrapportfrm").submit(function (e) {
        $('#loading_block').toggleClass('d-none');
        $('#rapportageExportBtn').prop('disabled', true );
        setTimeout(function () {
            location.reload();
        }, 5000);
    });
});
